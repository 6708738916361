import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { path: '/home' },
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('../views/Home.vue'),
    meta: { title: 'Quản trị Landingpage - Bizfly Cloud' },
  },
  {
    path: '/landingpages',
    name: 'LandingpageListView',
    component: () => import('../views/LandingpageList.vue'),
    meta: { title: 'Danh sách Landingpage - Bizfly Cloud' },
  },
  {
    path: '/landingpages/create',
    name: 'LandingpageCreateView',
    component: () => import('../views/LandingpageCreate.vue'),
    meta: {
      title: 'Tạo Landingpage - Bizfly Cloud',
      requiredRoles: ['admin', 'leader'],
    },
  },
  {
    path: '/landingpages/edit-ui/:landingId',
    name: 'LandingpageEditUiView',
    component: () => import('../views/LandingpageEditUi.vue'),
    meta: { title: 'Chỉnh sửa giao diện Landingpage - Bizfly Cloud' },
  },
  {
    path: '/landingpages/edit-info/:landingId',
    name: 'LandingpageEditInfoView',
    component: () => import('../views/LandingpageEditInfo.vue'),
    meta: { title: 'Chỉnh sửa thông tin chung Landingpage - Bizfly Cloud' },
  },
  {
    path: '/landingpages/version',
    name: 'LandingpageVersionView',
    component: () => import('../views/LandingpageVersion.vue'),
    meta: { title: 'Danh sách phiên bản Landingpage - Bizfly Cloud' },
  },
  {
    path: '/landingpages/clone/:landingId',
    name: 'LandingpageCloneView',
    component: () => import('../views/LandingpageClone.vue'),
    meta: {
      title: 'Nhân bản Landingpage - Bizfly Cloud',
      requiredRoles: ['admin', 'leader'],
    },
  },
  {
    path: '/landingpages/permission/:landingId',
    name: 'LandingpagePermissionView',
    component: () => import('../views/LandingpagePermission.vue'),
    meta: {
      title: 'Phân quyền chỉnh sửa Landingpage - Bizfly Cloud',
      requiredRoles: ['admin', 'leader'],
    },
  },
  {
    path: '/landingpages/history',
    name: 'LandingpageHistoryView',
    component: () => import('../views/LandingpageHistory.vue'),
    meta: { title: 'Lịch sử landingpage - Bizfly Cloud' },
  },
  {
    path: '/popups',
    name: 'PopupListView',
    component: () => import('../views/PopupList.vue'),
    meta: {
      title: 'Danh sách Popup quảng cáo - Bizfly Cloud',
      requiredRoles: ['admin', 'leader'],
    },
  },
  {
    path: '/popups/create',
    name: 'PopupCreateView',
    component: () => import('../views/PopupCreate.vue'),
    meta: {
      title: 'Tạo mới Popup quảng cáo - Bizfly Cloud',
      requiredRoles: ['admin', 'leader'],
    },
  },
  {
    path: '/popups/detail/:popupId',
    name: 'PopupEditView',
    component: () => import('../views/PopupDetail.vue'),
    meta: {
      title: 'Chi tiết Popup quảng cáo - Bizfly Cloud',
      requiredRoles: ['admin', 'leader'],
    },
  },
  {
    path: '/media-library',
    name: 'MediaLibraryView',
    component: () => import('../views/MediaLibrary.vue'),
    meta: { title: 'Thư viện tài nguyên - Bizfly Cloud' },
  },
  {
    path: '/users',
    name: 'UserListView',
    component: () => import('../views/UserList.vue'),
    meta: { title: 'Danh sách tài khoản', requiredRoles: ['admin', 'leader'] },
  },
  {
    path: '/users/detail/:userId',
    name: 'UserDetailView',
    component: () => import('../views/UserDetail.vue'),
    meta: { title: 'Thông tin tài khoản' },
  },
  {
    path: '/users/create',
    name: 'UserCreateView',
    component: () => import('../views/UserCreate.vue'),
    meta: { title: 'Tạo tài khoản', requiredRoles: ['admin'] },
  },
  {
    path: '/teams',
    name: 'TeamListView',
    component: () => import('../views/TeamList.vue'),
    meta: { title: 'Danh sách nhóm tài khoản', requiredRoles: ['admin'] },
  },
  {
    path: '/teams/create',
    name: 'TeamCreateView',
    component: () => import('../views/TeamCreate.vue'),
    meta: { title: 'Tạo nhóm tài khoản', requiredRoles: ['admin'] },
  },
  {
    path: '/teams/detail/:teamId',
    name: 'TeamDetailView',
    component: () => import('../views/TeamDetail.vue'),
    meta: { title: 'Chi tiết nhóm tài khoản', requiredRoles: ['admin'] },
  },
  {
    path: '/faq',
    name: 'FaqView',
    component: () => import('../views/faq/FaqList.vue'),
    meta: { title: 'FAQ', requiredRoles: ['admin', 'leader', 'editor'] },
  },
  {
    path: '/feedback',
    name: 'FeedbackView',
    component: () => import('../views/feedback/FeedbackList.vue'),
    meta: { title: 'Danh sách Feedback', requiredRoles: ['admin', 'leader'] },
  },
  {
    path: '/feedback/create',
    name: 'FaqCreateView',
    component: () => import('../views/feedback/FeedbackCreate.vue'),
    meta: { title: 'Tạo mới Feedback', requiredRoles: ['admin', 'leader'] },
  },
  {
    path: '/feedback/detail/:feedbackId',
    name: 'FeedbackDetailView',
    component: () => import('../views/feedback/FeedbackDetail.vue'),
    meta: { title: 'Chi tiết Feedback', requiredRoles: ['admin', 'leader'] },
  },
  {
    path: '/doc',
    name: 'DocumentView',
    component: () => import('../views/document/DocumentList.vue'),
    meta: { title: 'Danh sách Document', requiredRoles: ['admin', 'leader', 'editor'] },
  },
  {
    path: '/doc/create',
    name: 'DocumentCreateView',
    component: () => import('../views/document/DocumentCreate.vue'),
    meta: { title: 'Tạo mới Document', requiredRoles: ['admin', 'leader', 'editor'] },
  },
  {
    path: '/doc/detail/:docId',
    name: 'DocumentDetailView',
    component: () => import('../views/document/DocumentDetail.vue'),
    meta: { title: 'Chi tiết Document', requiredRoles: ['admin', 'leader', 'editor'] },
  },
  {
    path: '/press',
    name: 'PressView',
    component: () => import('../views/press/PressList.vue'),
    meta: {
      title: 'Danh sách Bài báo viết về Bizfly Cloud',
      requiredRoles: ['admin', 'leader', 'editor'],
    },
  },
  {
    path: '/press/create',
    name: 'PressCreateView',
    component: () => import('../views/press/PressCreate.vue'),
    meta: { title: 'Tạo mới bài báo', requiredRoles: ['admin', 'leader', 'editor'] },
  },
  {
    path: '/press/detail/:pressId',
    name: 'PressDetailView',
    component: () => import('../views/press/PressDetail.vue'),
    meta: { title: 'Chi tiết bài báo', requiredRoles: ['admin', 'leader', 'editor'] },
  },
  {
    path: '/template',
    name: 'ListTemplateView',
    component: () => import('../views/template/ListTemplate.vue'),
    meta: { title: 'Danh sách template' },
  },
  {
    path: '/template/create',
    name: 'TemplateCreateView',
    component: () => import('../views/template/TemplateCreate.vue'),
    meta: {
      title: 'Tạo Template - Bizfly Cloud',
    },
  },
  {
    path: '/template/edit-ui/:templateId',
    name: 'TemplateEditUiView',
    component: () => import('../views/template/TemplateEditUi.vue'),
    meta: { title: 'Chỉnh sửa giao diện Template - Bizfly Cloud' },
  },
  {
    path: '/template/edit-info/:templateId',
    name: 'TemplateEditInfoView',
    component: () => import('../views/template/TemplateEditInfo.vue'),
    meta: { title: 'Chỉnh sửa thông tin chung Template - Bizfly Cloud' },
  },
  {
    path: '/template/clone/:templateId',
    name: 'TemplateCloneView',
    component: () => import('../views/template/TemplateEditInfo.vue'),
    meta: {
      title: 'Nhân bản Template - Bizfly Cloud',
    },
  },
  {
    path: '/template/permission/:templateId',
    name: 'TemplatePermissionView',
    component: () => import('../views/template/TemplatePermission.vue'),
    meta: {
      title: 'Phân quyền chỉnh sửa Template - Bizfly Cloud',
    },
  },
  {
    path: '/500',
    name: 'InternalServerError',
    component: () => import('../views/error/InternalServerError.vue'),
    meta: {
      title: '500 - Bizfly Cloud',
    },
  },
  {
    path: '/502',
    name: 'BadGateway',
    component: () => import('../views/error/BadGateway.vue'),
    meta: {
      title: '502 - Bizfly Cloud',
    },
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import('../views/error/Forbidden.vue'),
    meta: {
      title: '403 - Bizfly Cloud',
    },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/error/PageNotFound.vue'),
    meta: {
      title: '404 - Bizfly Cloud',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
