<template>
  <div>
    <span @click="isOpenMenu = !isOpenMenu"
      ><bf-icon name="menu" class="menu-mobile"
    /></span>
    <bf-drawer
      :visible.sync="isOpenMenu"
      title="Menu"
      direction="ltr"
      size="300px"
    >
      <bf-menu :defaultActive="activePath" :collapse="false" :router="true">
        <bf-menu-item
          v-for="item in menuList"
          :key="`menu-list-${item?.path}`"
          :index="item?.path"
        >
          <bf-icon :name="item?.icon" size="m" />
          <span slot="title" class="ml-s">{{ item?.title }}</span>
        </bf-menu-item>
      </bf-menu>
    </bf-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOpenMenu: false,
      activePath: '',
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = `${to.meta.title}`;
        this.isOpenMenu = false;
        this.changeActivePath(to);
      },
    },
  },
  computed: {
    ...mapGetters('user', ['role']),
    menuList() {
      if (this.role === 'admin') {
        return [
          {
            title: 'Danh sách landingpage',
            path: '/landingpages',
            name: 'LandingpageListView',
            icon: 'static',
          },
          {
            title: 'Lịch sử landingpage',
            path: '/landingpages/history',
            name: 'LandingpageHistoryView',
            icon: 'document',
          },
          {
            title: 'Template',
            path: '/template',
            name: 'TemplateListView',
            icon: 'file',
          },
          {
            title: 'Thư viện file tài nguyên',
            path: '/media-library',
            name: 'MediaLibraryView',
            icon: 'picture-landscape',
          },
          {
            title: 'Danh sách tài khoản',
            path: '/users',
            name: 'LandingpageListView',
            icon: 'person',
          },
          {
            title: 'Vai trò và phân quyền',
            path: '/teams',
            name: 'TeamListView',
            icon: 'edit',
          },
          {
            title: 'Popup quảng cáo',
            path: '/popups',
            name: 'PopupListView',
            icon: 'mps',
          },
          {
            title: 'FAQ',
            path: '/faq',
            name: 'FaqView',
            icon: 'support',
          },
          {
            title: 'Feedback',
            path: '/feedback',
            name: 'FeedbackView',
            icon: 'chat-warning',
          },
          {
            title: 'Document',
            path: '/doc',
            name: 'DocumentView',
            icon: 'log',
          },
          {
            title: 'Press',
            path: '/press',
            name: 'PressView',
            icon: 'group-member',
          },
        ];
      }
      if (this.role === 'leader') {
        return [
          {
            title: 'Danh sách landingpage',
            path: '/landingpages',
            name: 'LandingpageListView',
            icon: 'static',
          },
          {
            title: 'Lịch sử landingpage',
            path: '/landingpages/history',
            name: 'LandingpageHistoryView',
            icon: 'document',
          },
          {
            title: 'Template',
            path: '/template',
            name: 'TemplateListView',
            icon: 'file',
          },
          {
            title: 'Thư viện file tài nguyên',
            path: '/media-library',
            name: 'MediaLibraryView',
            icon: 'picture-landscape',
          },
          {
            title: 'Danh sách tài khoản',
            path: '/users',
            name: 'LandingpageListView',
            icon: 'person',
          },
          {
            title: 'Popup quảng cáo',
            path: '/popups',
            name: 'PopupListView',
            icon: 'mps',
          },
          {
            title: 'FAQ',
            path: '/faq',
            name: 'FaqView',
            icon: 'support',
          },
          {
            title: 'Feedback',
            path: '/feedback',
            name: 'FeedbackView',
            icon: 'chat-warning',
          },
          {
            title: 'Document',
            path: '/doc',
            name: 'DocumentView',
            icon: 'log',
          },
          {
            title: 'Press',
            path: '/press',
            name: 'PressView',
            icon: 'group-member',
          },
        ];
      }
      if (this.role === 'editor') {
        return [
          {
            title: 'Danh sách landingpage',
            path: '/landingpages',
            name: 'LandingpageListView',
            icon: 'static',
          },
          {
            title: 'Lịch sử landingpage',
            path: '/landingpages/history',
            name: 'LandingpageHistoryView',
            icon: 'document',
          },
          {
            title: 'Template',
            path: '/template',
            name: 'TemplateListView',
            icon: 'file',
          },
          {
            title: 'Thư viện file tài nguyên',
            path: '/media-library',
            name: 'MediaLibraryView',
            icon: 'picture-landscape',
          },
          {
            title: 'FAQ',
            path: '/faq',
            name: 'FaqView',
            icon: 'support',
          },
          {
            title: 'Document',
            path: '/doc',
            name: 'DocumentView',
            icon: 'log',
          },
          {
            title: 'Press',
            path: '/press',
            name: 'PressView',
            icon: 'group-member',
          },
        ];
      }
      return [
        {
          title: 'Danh sách landingpage',
          path: '/landingpages',
          name: 'LandingpageListView',
          icon: 'static',
        },
        {
          title: 'Lịch sử landingpage',
          path: '/landingpages/history',
          name: 'LandingpageHistoryView',
          icon: 'document',
        },
        {
          title: 'Template',
          path: '/template',
          name: 'TemplateListView',
          icon: 'file',
        },
      ];
    },
  },
  methods: {
    changeActivePath(to) {
      const checkIndexPath = this.menuList.findIndex((menu) => menu?.name === to?.name);
      if (checkIndexPath !== -1) {
        this.activePath = this.menuList?.[checkIndexPath]?.path;
        return;
      }
      this.activePath = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-mobile {
  cursor: pointer;
  padding: 3px 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border 0.3s;
  &:hover {
    border: 1px solid #757575;
    transition: border 0.3s;
  }
}
</style>
